!((document, $) => {
  'use strict';

  $(document).ready(function() {
    if ($('.l-middle').length) {
      if (
        $('.l-sidebar').length &&
        $.trim($('.l-sidebar').html()).length === 0
      ) {
        $('.l-content').addClass('full-width');
      }
    }
  });
})(document, jQuery);
