!((document, $) => {
  'use strict';

  /**
   * Function to toggle menu visibility
   **/
  function addToggleButton() {
    const toggleButtonHtml = `
    <input id="menu-toggle-state" class="menu-toggle-state element-invisible" type="checkbox" aria-controls="menu-toggle-state">
      <label class="menu-toggle-button" for="menu-toggle-state"
      "><span class="menu-toggle-button-icon"></span><span class="menu-toggle-assistive-text element-invisible">Toggle menu visibility</span></label>
    `;
    $('.l-header-inner').prepend(toggleButtonHtml);
  }

  $(document).ready(function() {
    addToggleButton();
  });

  /**
   * Function to toggle menu visibility
   **/
  function toggleMenu() {
    const menuToggleButton = $('.menu-toggle-button');
    const menuUl = $('.header-menu');
    const mainMenuClosed = $('.block-system-main-menu');

    // Add click event listener to the toggle button
    menuToggleButton.on('click', function() {
      menuUl.toggleClass('menu-expanded');
      mainMenuClosed.toggleClass('closed-main-menu');
    });
  }

  $(document).ready(function() {
    toggleMenu();
  });
})(document, jQuery);
